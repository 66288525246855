<template>
  <div>
    <vx-card>
      <div>
        <div class="w-full my-2">
          <label>Invoice Title</label>
          <vs-input
            v-validate="'required'"
            placeholder="Invoice Title"
            name="title"
            v-model="invoice.title"
            class="w-full"
          />
        </div>
        <div class="w-full my-2">
          <label>Sub Title</label>
          <vs-input
            placeholder="sub title"
            v-model="invoice.subtitle"
            class="w-full"
          />
        </div>
      </div>

      <div class="flex my-10">
        <div class="w-1/2">
          <table>
            <tr>
              <th>To:</th>
              <td>
                <vs-input
                  v-model="invoice.to"
                />
              </td>
            </tr>
            <tr>
              <th>Business Name</th>
              <td>
                <vs-input
                  v-model="invoice.receiverBusinessName"
                />
              </td>
            </tr>
            <tr>
              <th>Address</th>
              <td>
                <vs-input
                  v-model="invoice.receiverAddress"
                />
              </td>
            </tr>
          </table>
        </div>

        <!--  From -->

        <div class="w-1/2">
          <div>
            <table>
              <tr>
                <th>
                  Sender Name
                </th>
                <td>
                  <vs-input
                    v-model="invoice.senderName"
                  />
                </td>
              </tr>
              <tr>
                <th>Sender Designation</th>
                <td>
                  <vs-input
                    v-model="invoice.senderDesignation"
                    class="w-full"
                  />
                </td>
              </tr>
              <tr>
                <th>Sender Business Name</th>
                <td>
                  <vs-input
                    v-model="invoice.senderBusinessName"
                  />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-12 my-4">
        <table class="w-full table-with-border">
          <thead>
          <tr>
            <th style="width: 45%;">Description</th>
            <th style="width: 15%;">Quantity(Ton)</th>
            <th style="width: 15%;">Unit Price(TK)</th>
            <th style="width: 15%;">Total(TK)</th>
            <th style="width: 10%;">
              <feather-icon icon="PlusIcon"
                            @click="addMoreItem"
              />
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in invoice.items" :key="index">
            <td style="width: 30%">
              <vs-input
                v-model="item.description"
                class="w-full"
              />
            </td>
            <td>
              <vs-input
                v-model="item.quantity"
                class="w-full"
                @change="()=>quantityChange(item)"
              />
            </td>
            <td>
              <vs-input
                v-model="item.rate"
                class="w-full"
                @change="()=>priceChange(item)"

              />
            </td>
            <td class="text-center">
              <div>{{ item.total }}</div>
            </td>
            <td>
              <feather-icon icon="MinusIcon" @click="()=>removeItem(index)"
                            class="hover:text-primary cursor-pointer"
              ></feather-icon>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr>
            <td colspan="3"></td>
            <td>Sub Total</td>
            <td>{{ invoice.itemsTotal }}</td>
          </tr>
          </tfoot>
        </table>
      </div>
      <div class="flex my-3">
        <div class="w-1/3 mx-2">
          <table>
            <tr>
              <th>A/C Name</th>
              <td>
                <vs-input
                  v-model="invoice.accountName"
                  class="w-full"
                />
              </td>
            </tr>
            <tr>
              <th>Account No.</th>
              <td>
                <vs-input
                  v-model="invoice.accountNo"
                  class="w-full"
                />
              </td>
            </tr>
            <tr>
              <th>Bank Name</th>
              <td>
                <vs-input
                  v-model="invoice.bankName"
                  class="w-full"
                />
              </td>
            </tr>
            <tr>
              <th>Brunch Name</th>
              <td>
                <vs-input
                  v-model="invoice.brunchName"
                  class="w-full"
                />
              </td>
            </tr>
            <tr>
              <th>Brunch Address</th>
              <td>
                <vs-input
                  v-model="invoice.brunchAddress"
                  class="w-full"
                />
              </td>
            </tr>
            <tr>
              <th>Routing</th>
              <td>
                <vs-input
                  v-model="invoice.routingNo"
                  class="w-full"
                />
              </td>
            </tr>
          </table>
        </div>
        <div class="w-2/3 mx-2"><h4>Adjustment</h4>
          <table class="overflow-hidden">
            <tr>
              <th style="width: 50%;">
                Title
              </th>
              <th style="width: 10%;">quantity</th>
              <th style="width: 20%;">price</th>
              <th style="width: 20%;">
                Amount
              </th>
              <th>
                <feather-icon icon="PlusIcon" @click="addMoreAdjustment"
                              class="hover:text-primary cursor-pointer"
                ></feather-icon>
              </th>
            </tr>
            <tr></tr>
            <tr v-for="(adjustment, index) in invoice.adjustments"
                :key="index"
            >
              <td>
                <vs-input
                  v-model="adjustment.title"
                  class="w-full"
                />
              </td>
              <td>
                <vs-input
                  v-model="adjustment.quantity"
                  class="w-full"
                  @change="()=>adjustmentQuantityChange(adjustment)"
                />
              </td>
              <td>
                <vs-input
                  v-model="adjustment.unit_price"
                  class="w-full"
                  @change="()=>adjustmentPriceChange(adjustment)"
                />
              </td>
              <td>
                <vs-input
                  v-model="adjustment.amount"
                  @change="()=>onAdjustmentChange(adjustment)"
                  class="w-full"
                />
              </td>
              <td>
                <feather-icon icon="MinusIcon" @click="()=>removeAdjustment(index)"
                              class="hover:text-primary cursor-pointer"
                ></feather-icon>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <th>
                Total DUE
              </th>
              <td>
                {{ invoice.adjustedPrice }}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <h5 class="my-1">Description</h5>
        <vs-textarea v-model="invoice.description"></vs-textarea>
      </div>
      <div class="flex justify-end" @click="saveAndPrint">
        <vs-button>Submit</vs-button>
      </div>
    </vx-card>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      invoice: {
        to: "",
        receiverBusinessName: "",
        receiverAddress: "",
        items: [{
          description: "",
          quantity: 1,
          rate: 0,
          total: 0
        }],
        itemsTotal: 0,
        adjustments: [{
          title: "Fuel Bill",
          unit_price: 0,
          quantity: 1,
          amount: 0
        }, {
          title: "Lubricant Bill",
          unit_price: 0,
          quantity: 1,
          amount: 0
        }, {
          title: "Token Bill",
          unit_price: null,
          quantity: null,
          amount: 0
        }, {
          title: "Cash Received",
          unit_price: null,
          quantity: null,
          amount: 0
        }],
        adjustedPrice: 0,
        title: "",
        subtitle: "",
        accountName: "Noble Navigation and Shipping Line",
        accountNo: "0021020061169",
        bankName: "Al Arafah Islami Bank Limited",
        brunchName: "Motijheel",
        brunchAddress: "161(Rahman Address),Motijheel C/A, Dhaka-1000",
        routingNo: "",
        description: "",
        senderName: "Mahmudul Hasan",
        senderDesignation: "CEO and Proprietor",
        senderBusinessName: "Noble Naviagation & Shipping Line"
      },
      allowToLeave: false,
    }
  },
  methods: {
    addMoreAdjustment: function () {
      this.invoice.adjustments.push({
        title: "",
        amount: 0
      })
    },
    removeAdjustment: function (index) {
      this.invoice.adjustments = this.invoice.adjustments.filter( (e, i) => {
        return i !== index
      });
      this.calculateItemPrice()
    },
    onAdjustmentChange: function (t) {
      t.quantity = null;
      t.unit_price = null;
      this.calculateItemPrice()
    },
    addMoreItem: function () {
      this.invoice.items.push({
        description: "",
        rate: 0,
        quantity: 1,
        total: 0
      })
    },
    removeItem: function (t) {
      this.invoice.items = this.invoice.items.filter( (element, i) => {
        return i !== t
      });

      this.calculateItemPrice()
    },
    quantityChange: function (item) {
      /*var i = this.invoice.items.find(function (it) {
        return it. === e
      });*/
      item.total = item.rate * item.quantity;
      this.calculateItemPrice()
    },
    priceChange: function (item) {
      /*var i = this.invoice.items.find(function (t, i) {
        return i === e
      });*/
      item.total = item.quantity * item.rate;
      this.calculateItemPrice()
    },
    calculateItemPrice: function () {
      let t = this.invoice.items.reduce((t, e) => {
        return t + parseInt(e.total)
      }, 0);

      let e = this.invoice.adjustments.reduce( (t, e) => {
        return t + parseInt(e.amount)
      }, 0);

      this.invoice.itemsTotal = t;
      this.invoice.adjustedPrice = t - e;
    },
    adjustmentQuantityChange: function (t) {
      t.amount = t.quantity * t.unit_price;
      this.calculateItemPrice()
    },
    adjustmentPriceChange: function (t) {
      t.amount = t.quantity * t.unit_price;
      this.calculateItemPrice()
    },
    saveAndPrint: function () {
      this.axios.put("/invoices/"+ this.$route.params.id, {
        invoice_data: JSON.stringify(this.invoice)
      }).then((res) => {
        let {status} = res.data;
        this.allowToLeave = true;
        if(status === "success") {
          this.$router.push({
            path: "/invoices"
          })
        }
      }).catch( (t) => {
        return console.log(t)
      })
    }
  },
  beforeRouteLeave: function (t, e, next) {
    if (this.allowToLeave)
      return next();
    this.$vs.dialog({
      type: "confirm",
      color: "danger",
      title: "Are you sure to leave this page?",
      text: "All Progress will be lost",
      accept: function () {
        next()
      }
    })
  },
  mounted() {
    this.axios.get('/invoices/' + this.$route.params.id).then((res) => {
      let data = res.data;

      if(data){
        this.invoice = JSON.parse(data.invoice_data);
      }
    }).catch(function (t) {
      return console.log(t)
    })
  }
}
</script>
